import { SWRConfig } from "swr";
import type { AppProps } from "next/app";
import { use, useEffect, useState } from "react";
import { handleAPIError, handleAPIRequest } from "@/utils/handleAPIError";
import localFont from "next/font/local";
import Head from "next/head";

import "../styles/global.css";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/core/styles/Radio.css";
import "@mantine/core/styles/global.css";

import { MobileWrapper } from "@/template/MobileWrapper";
import { MantineProvider } from "@mantine/core";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useIsomorphicEffect } from "@mantine/hooks";
import { GetServerSideProps } from "next";

export const DOMAIN = "https://dev.disco-fashion.com";
// export const DOMAIN = "http://localhost:3001";

const pretendard = localFont({
  variable: "--pretendard",
  src: [
    {
      path: "../src/fonts/Pretendard-Regular.otf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../src/fonts/Pretendard-Medium.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../src/fonts/Pretendard-SemiBold.otf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../src/fonts/Pretendard-Bold.otf",
      weight: "700",
      style: "normal",
    },
  ],
});

interface CustomAppProps extends AppProps {
  pageProps: {
    ogImageUrl?: string;
  };
}

export default function App({ Component, pageProps }: CustomAppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const ogImage = pageProps.ogImageUrl || `${DOMAIN}/assets/open-graph.png`;

  useIsomorphicEffect(() => {
    handleAPIError();
    handleAPIRequest();

    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    const isAndroid = /android/.test(userAgent);
    const isWebView = /(wv)|webview/.test(userAgent);

    function openApp() {
      if (!isWebView) {
        if (isIOS || isAndroid) {
          window.location.href = "exp+disco://";
        }
      }
    }

    openApp();
  }, []);

  return (
    <div className="">
      <Head>
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="패션 유형 결과" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={ogImage} />
      </Head>
      <style jsx global>
        {`
          html {
            --pretendard: ${pretendard.style.fontFamily};
          }
        `}
      </style>
      <SWRConfig
        value={{
          suspense: false,
          revalidateOnFocus: false,
          dedupingInterval: 10000,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <MantineProvider>
            <MobileWrapper>
              <Component {...pageProps} />
            </MobileWrapper>
          </MantineProvider>
        </QueryClientProvider>
        <ToastContainer
          position="bottom-center"
          icon={false}
          hideProgressBar
          autoClose={3000}
          pauseOnFocusLoss={false}
          closeOnClick={false}
          limit={3}
          className={() =>
            "w-full fixed bottom-[50px] z-[10000] box-border p-4"
          }
          toastClassName={
            "rounded-12 shadow-popup text-white-85 relative mb-8 box-border flex cursor-pointer items-center justify-center"
          }
          bodyClassName={() =>
            "my-auto mx-0 flex flex-1 items-center [&_div]:flex [&_div]:items-center [&_div]:w-full"
          }
          containerId="root_container"
        />
      </SWRConfig>
    </div>
  );
}
